.home {
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.876), rgba(0, 0, 0,  0.876)), url(../images/pexels-pixabay-270348.jpg);
    backdrop-filter: blur(25px);
    --webkit-backdrop-filter: blur(25px);
    background-size: cover;
    background-repeat: no-repeat;
}


.portrait {
    border-radius: 50%;;
}

.button {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: white !important;
    color: white !important;
    transition: all 1s;
}

.button:hover {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: white !important;
    color: black !important;
    background-color: white !important;
}

.react-typewriter-text-wrap {
    display: inline-block;
}

.arthur-hero {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* max-width: 65%; */
}
