.projects-container {
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.671), rgba(0, 0, 0, 0.671)), url(../images/projects.jpg);
    backdrop-filter: blur(25px);
    --webkit-backdrop-filter: blur(25px);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: scroll;
}



.projects-header {
    width:100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: none;
}

.projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap:1%;
}

.projects .project-card {
    max-width: 350px;
    background: white;
    color: black;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    /* flex-grow: 0; */
    flex-basis: 33.3%;
    margin-top: 2%;
}

.projects .project-card  .card-header{
    background: transparent;
    color: black;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .projects {
        flex-direction: column;
        overflow: scroll;
    }

    .projects-header {
        position: relative;
        overflow: none;
        transform: none;
        left: 0;
        top: 10%;
    }
    
}